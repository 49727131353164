/* You can add global styles to this file, and also import other style files */
@use "node_modules/@ngneat/hot-toast/src/styles/styles.scss";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
@font-face {
  font-family: "Atyp";
  src: local("Atyp Medium"), local("Atyp-Medium"),
    url("assets/fonts/AtypDisplay-Medium.woff2") format("woff2"),
    url("assets/fonts/AtypDisplay-Medium.woff") format("woff"),
    url("assets/fonts/AtypDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "U8";
  src: url("https://db.onlinewebfonts.com/t/7017698b5c5a2cc397cd3b0c0984d76f.eot");
  src: url("https://db.onlinewebfonts.com/t/7017698b5c5a2cc397cd3b0c0984d76f.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/7017698b5c5a2cc397cd3b0c0984d76f.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/7017698b5c5a2cc397cd3b0c0984d76f.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/7017698b5c5a2cc397cd3b0c0984d76f.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/7017698b5c5a2cc397cd3b0c0984d76f.svg#U8")
      format("svg");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0.3rem;
    background: transparent;
    height: 2px;
    scroll-snap-type: x proximity;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

body {
  font-family: "DM Sans" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

:root {
  --primary-color: #3d1560;
  --secondary-color: #df678c;
  --warning-color: #eaac30;
  --bg-warning: #fff4eb;
  --text-warning-2: #ff9943;
  --danger-color: #e61b00;
  --bg-primary: #e8e9ed;
  --primary-hover: #4a1c7a;
  --primary-light: #edd9ff;
  --text-secondary: #6d26ab;
  --text-success: #18bb0c;
  --muted: #797b89;
  --header: #1a202c;
  --bg-light: #f8f8fa;
  --bg-purple: #9b53d9;
  --content-color: #383a47;
  --white-color: #fff;
}

html {
  height: 100%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

b {
  pointer-events: inherit;
}

h1 {
  font-family: "U8";
  font-size: 2.125rem;
  font-weight: 700;
  color: var(--header);
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-secondary {
  color: var(--secondary-color) !important;
}

button:disabled {
  opacity: 0.5;
}

.mb-90 {
  margin-bottom: 90px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-120 {
  margin-top: 120px;
}

.w-420 {
  width: 420px;
}

.w-45 {
  width: 45% !important;
}

.pointer {
  cursor: pointer;
}

.parent {
  padding: 0% 7% 0% 7%;
}

.readOnly {
  background-color: #f8f8fa !important;
}

.btn-outline {
  border: 1.5px solid #3d1560;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.btn-outline:hover {
  border: 1.5px solid #3d1560;
  background: #3d1560 !important;
  color: #ffffff !important;
}

.btn2-outline {
  border: 1px solid #c6c8d3;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.btn3-outline {
  border: 1px solid #e2e8f0 !important;
  border-radius: 10px;
}

.btn4-outline {
  border: 1px dashed #c6c8d3;
  width: 25.18px;
  height: 25.18px;
}

.btn5-outline {
  border: 2px solid #383a47;
}

.margin-r {
  display: inline-flex;
}

.color-text {
  color: #797b89 !important;
}

.color-text2 {
  color: #c6c8d3;
}

.color-purple {
  color: #3d1560;
}

.color-purple-light {
  color: #6d26ab !important;
}

.color-header {
  color: #1b1c20 !important;
}

.color-body {
  color: #383a47 !important;
}

.color-grey {
  color: #e8e9ed !important;
}

.color-primary {
  color: #e78da9 !important;
}

.color-password {
  color: #d84773 !important;
}

.color-red {
  color: #e61b00 !important;
}

.color-white {
  color: #ffffff;
}

.color-pink {
  color: #df678c;
}

.color-grey600 {
  color: #718096;
}

.color-grey500 {
  color: #a0aec0;
}

.color-grey900 {
  color: #1a202c;
}

.color-green {
  color: #18bb0c;
}

.color-yellow {
  color: #eaac30;
}

.color-orange {
  color: #ff9943;
}

.bg-purple {
  background: #3d1560;
}

.bg-grey {
  background: #e7e5ff !important;
}

.bg-grey-dark {
  background: #f8f8fa;
}

.bg-pry {
  background: #df678c !important;
}

.bg-white {
  background: #fff;
}

.bg-pink {
  background: #ffe5ed !important;
}

.bg-pink2 {
  background: #f7d9e2;
}

.bg-accent {
  background: #d7d1f5;
}

.bg-lgreen {
  background: #eaf9de;
}

.bg-lred {
  background: #fff2f1;
}

.bg-lpink {
  background: #f7d9e2;
}

.bg-neutral {
  background: #c6c8d3;
}

.bg-orange {
  background: #e61b00;
}

.capitalize {
  text-transform: capitalize;
}

.round-8 {
  border-radius: 8px;
}

.round-full {
  border-radius: 100%;
}

.round-10 {
  border-radius: 10px;
}

.round-12 {
  border-radius: 12px;
}

.round-16 {
  border-radius: 16px;
}

.round-24 {
  border-radius: 24px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.font-1 {
  font-size: 1px !important;
}

.font-2 {
  font-size: 2px !important;
}

.font-3 {
  font-size: 3px !important;
}

.font-4 {
  font-size: 4px !important;
}

.font-5 {
  font-size: 5px !important;
}

.font-6 {
  font-size: 6px !important;
}
.font-7 {
  font-size: 7px !important;
}

.font-8 {
  font-size: 8px !important;
}

.font-9 {
  font-size: 9px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13.617px;
  line-height: 160%;
}

.font-14 {
  font-size: 0.875rem !important;
  line-height: 21px !important;
}

.font-15 {
  font-size: 15px;
  line-height: 24px;
}

.font-16 {
  font-size: 16px;
  line-height: 22px;
}

.font-17 {
  font-size: 17.0213px;
  line-height: 160%;
}

.font-18 {
  font-size: 18px;
  line-height: 28px;
}

.font-20 {
  font-size: 20px;
  line-height: 34px;
}

.font-24 {
  font-size: 24px;
  line-height: 135%;
}

.font-29 {
  font-size: 29.703px;
  line-height: 38px;
}

.font-30 {
  font-size: 30px;
  line-height: 38px;
}

.font-32 {
  font-size: 32px;
  line-height: 37px;
}

.font-34 {
  font-size: 34px;
  line-height: 48px;
}

.font-36 {
  font-size: 36px;
  line-height: 44px;
}

.font-38 {
  font-size: 38px;
  line-height: 124%;
}

.font-40 {
  font-size: 40px;
  line-height: 110%;
}

.font-46 {
  font-size: 46px;
  line-height: 140%;
}

.font-48 {
  font-size: 48px;
  line-height: 55px;
}

.animate-rotate {
  animation: rotation 4s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.animate-bounce {
  animation: bounce 3s infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}

//animation ends

input,
select,
textarea {
  border: none;
  outline: none;
}

.w-full {
  width: 100% !important;
}

.mr-btn-20 {
  margin-right: 20px;
}

.multiselect-dropdown .dropdown-list {
  overflow-y: hidden;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  border: 1px solid #3d1560 !important;
  background: #3d1560 !important;
}

.multiselect-dropdown .dropdown-btn {
  display: inline-block;
  border: 1px solid #adadad;
  width: 100%;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.52857143;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid #c6c8d3;
  box-sizing: border-box;
  border-radius: 15px !important;
  padding: 1rem !important;
}

//otp
.otp-input {
  border: 1px solid #c6c8d3 !important;
  border-radius: 12px !important;
  font-weight: 850;
  font-size: 24px;
  line-height: 28px;
  color: #1b1c20;
}

// mobile responsiveness
@media only screen and (max-width: 950px) {
  .md-no-display {
    display: none !important;
  }

  .md-mb-30 {
    margin-bottom: 20px;
  }

  .md-flex-column {
    flex-direction: column;
  }

  .md-w-wk {
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .md-w-auto {
    width: auto;
  }

}

@media screen and (max-width: 750px) {
  .smd-order-2 {
    order: 2;
  }

  .smd-mb-20 {
    margin-bottom: 20px;
  }

  .smd-mb-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (max-width: 450px) {
  .sm-no-display {
    display: none;
  }

  .sm-w-180 {
    width: 180px !important;
  }

  .font-11 {
    font-size: 8px !important;
    line-height: 11px !important;
  }

  .font-13 {
    font-size: 10px;
    line-height: 120%;
  }

  .font-17 {
    font-size: 14px;
    line-height: 120%;
  }

  .font-18 {
    font-size: 15px;
    line-height: 22px;
  }

  .font-20 {
    font-size: 17px;
    line-height: 30px;
  }

  .font-24 {
    font-size: 21px;
    line-height: 125%;
  }

  .font-29 {
    font-size: 25px;
    line-height: 33px;
  }

  .font-32 {
    font-size: 28px;
    line-height: 35px;
  }

  .font-34 {
    font-size: 30px;
    line-height: 38px;
  }

  .font-36 {
    font-size: 32px;
    line-height: 40px;
  }

  .font-38 {
    font-size: 33px;
    line-height: 104%;
  }

  .font-46 {
    font-size: 43px;
    line-height: 140%;
  }

  .font-48 {
    font-size: 40px;
    line-height: 48px;
  }
}
.transaction-notification {
  border-radius: 8px;
  padding: 16px;
  background-color: white;
  align-items: start !important;
}

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

#onboarding-phone {
  background-color: #e2e8f0 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-animation: textfield;
}

// for sidenav
.navigation .nav-link {
  svg {
    path {
      transition: all 0.2s ease;
    }
  }

  &.active,
  &:hover {
    svg {
      path {
        fill: white;
      }
    }
  }
}

.modal-backdrop {
  opacity: 0.2 !important;
}

// for settings
.settings .nav-link {
  svg {
    path {
      transition: all 0.2s ease;
    }
  }

  &.active,
  &:hover {
    // color: #6d26ab !important;
    svg {
      path {
        fill: #130f26;
      }
    }
  }
}

.submit-btn {
  width: 100%;
  height: 3.375em;
  border-radius: 10px;
  border: none;
  background: #3d1560;
  color: white;
  font-weight: 700;
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:disabled:hover {
    background: #3d1560;
  }
}

.sr-only {
  display: none !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

emoji-picker {
  transform: translate(51px, -156.4px) !important;
}

.shepherd-header {
  background: #fff !important;
  padding-bottom: 0 !important;

  h3 {
    color: #1b1c20;
    font-family: DM Sans;
    font-weight: 700;
    line-height: 1.1875rem; /* 135.714% */
  }
}

.shepherd-text {
  font-family: DM Sans;
  font-weight: 400;
  line-height: 1.1875rem !important; /* 135.714% */
  font-size: 0.875rem !important; /* 14px */
  padding-inline: 1rem !important;
}

.shepherd-content {
  border-radius: 0.5rem !important;
}

.shepherd-button {
  border-radius: 0.38094rem !important;
  background: #df678c !important;
  padding: 0.7rem 1.5rem !important;
  color: #fff !important;

  &.shepherd-button-secondary {
    background: none !important;
    border: none !important;
    font-weight: 700;
    color: #df678c !important;
    text-decoration: underline !important;
  }
}

.shepherd-arrow:before {
  background: #fff !important;
}

.shepherd-footer {
  position: relative;
}

.shepherd-footer .shepherd-steps-count {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 50%;
  aspect-ratio: 1/1;
  color: #fff;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  --progress: 70deg;
}

.shepherd-footer .shepherd-steps-count::after {
  content: "";
  position: absolute;
  left: 0.1rem;
  top: 0.11rem;
  width: 2.8rem;
  height: 2.8rem;
  background: #df678c;
  border-radius: 50%;
  aspect-ratio: 1/1;
  z-index: -1;
}

.shepherd-footer .shepherd-steps-count::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 3rem;
  height: 3rem;
  background: conic-gradient(
    #3d1560 0deg var(--progress),
    #df678c var(--progress) 360deg
  );
  border-radius: 50%;
  aspect-ratio: 1/1;
  z-index: -2;
}
